import { Caterer } from 'lib/algolia/model';
import yup from 'lib/yup';
import { createSelectorQuestion } from 'qualification/schema/questions/utils';

export const CATERING_CATEGORIES = [
  'breakfast',
  'fingerfood',
  'business_lunch',
  'cakes_and_sweets',
  'christmas',
  'street_food',
  'drinks',
  'bbq',
  'buffet',
  'fine_dining',
  'summer_party',
  'other',
  'event',
] as const;

export const PRICE_PER_PERSON_OF_CATEGORY: Record<
  CateringCategoryValue,
  CategoryPriceData
> = {
  bbq: createCategoryData(15, 20),
  fingerfood: createCategoryData(12, 20),
  breakfast: createCategoryData(11, 15),
  buffet: createCategoryData(14, 20),
  business_lunch: createCategoryData(11, 20),
  cakes_and_sweets: createCategoryData(10, 15),
  christmas: createCategoryData(25, 35),
  summer_party: createCategoryData(35, 45),
  drinks: createCategoryData(11, 20),
  fine_dining: createCategoryData(25, 30),
  other: createCategoryData(15, 25),
  street_food: createCategoryData(25, 40),
  event: createCategoryData(15, 25),
};

interface CategoryPriceData {
  minPricePerPerson: number;
  defaultPricePerPerson: number;
}
function createCategoryData(minValue: number, defaultValue: number) {
  return { minPricePerPerson: minValue, defaultPricePerPerson: defaultValue };
}

const CATERER_CATERING_CATEGORY = [
  'Breakfast',
  'Fingerfood & Snacks',
  'Business Lunch',
  'Cake & Sweets',
  'Foodtruck/Streetfood',
  'Drinks & Cocktails',
  'Buffet',
  'Dinner & Fine Dining',
] as const;

const CATERER_CATERING_CATEGORY_MAPPING: Record<
  CatererCateringCategory,
  CateringCategoryValue
> = {
  Breakfast: 'breakfast',
  Buffet: 'buffet',
  'Business Lunch': 'business_lunch',
  'Cake & Sweets': 'cakes_and_sweets',
  'Dinner & Fine Dining': 'fine_dining',
  'Drinks & Cocktails': 'drinks',
  'Fingerfood & Snacks': 'fingerfood',
  'Foodtruck/Streetfood': 'street_food',
};
type CatererCateringCategory = typeof CATERER_CATERING_CATEGORY[number];

export type CateringCategoryValue = typeof CATERING_CATEGORIES[number];
export function isValidCateringCategory(
  value: unknown
): value is CateringCategoryValue {
  return CATERING_CATEGORIES.includes(value as CateringCategoryValue);
}

export const CATERING_CATEGORY_ITEMS = [
  {
    value: 'breakfast',
    labelEn: 'Breakfast',
    labelDe: 'Frühstück',
    image: '/artifacts/images/survey/occasion/breakfast.webp',
  },
  {
    value: 'fingerfood',
    labelEn: 'Fingerfood & Snacks',
    labelDe: 'Fingerfood & Snacks',
    image: '/artifacts/images/survey/occasion/fingerfood.webp',
  },
  {
    value: 'business_lunch',
    labelEn: 'Lunch',
    labelDe: 'Lunch',
    image: '/artifacts/images/survey/occasion/lunch.webp',
  },
  {
    value: 'cakes_and_sweets',
    labelEn: 'Cakes & Sweets',
    labelDe: 'Kuchen & Süßes',
    image: '/artifacts/images/survey/occasion/cakes_and_sweets.webp',
  },
  {
    value: 'street_food',
    labelEn: 'Food Truck',
    labelDe: 'Foodtruck',
    image: '/artifacts/images/survey/occasion/foodtruck.webp',
  },
  {
    value: 'summer_party',
    labelEn: 'Summer Party',
    labelDe: 'Sommerparty',
    image: '/artifacts/images/survey/occasion/summer_party.webp',
  },
  {
    value: 'drinks',
    labelEn: 'Drinks & Cocktails',
    labelDe: 'Getränke & Cocktails',
    image: '/artifacts/images/survey/occasion/drinks_and_cocktails.webp',
  },
  {
    value: 'buffet',
    labelEn: 'Buffet',
    labelDe: 'Buffet',
    image: '/artifacts/images/survey/occasion/buffet.webp',
  },
  {
    value: 'fine_dining',
    labelEn: 'Dinner & Fine Dining',
    labelDe: 'Dinner & Fine Dining',
    image: '/artifacts/images/survey/occasion/dinner.webp',
  },
  {
    value: 'other',
    labelEn: 'I am not sure',
    labelDe: 'Ich bin mir nicht sicher',
    image: '/artifacts/images/survey/occasion/other.webp',
  },
] as const;

export const cateringCategoriesQuestion = Object.freeze(
  createSelectorQuestion({
    id: 'catering_categories',
    getValidator: ({ selectedCaterer }) => {
      const supportedCategoryValues = getSupportedCategories(selectedCaterer);
      return yup
        .array()
        .of(
          yup
            .mixed<CateringCategoryValue>()
            .strict()
            .required()
            .oneOf(supportedCategoryValues)
        )
        .min(1)
        .required();
    },
    getItems: ({ selectedCaterer }) => {
      const supportedCategoryValues = getSupportedCategories(selectedCaterer);
      return CATERING_CATEGORY_ITEMS.filter((item) =>
        supportedCategoryValues.includes(item.value)
      );
    },
  })
);

function getSupportedCategories(caterer?: Caterer): CateringCategoryValue[] {
  if (caterer) {
    return Array.from(
      new Set(
        caterer.catering_categories.map(
          (category) =>
            CATERER_CATERING_CATEGORY_MAPPING[
              category as CatererCateringCategory
            ] || 'other'
        )
      )
    );
  }

  return [...CATERING_CATEGORIES];
}

export type CateringCategoriesQuestion = typeof cateringCategoriesQuestion;
